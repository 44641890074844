.selected_cursor {
    cursor: pointer;
    border: 4px solid #3e30ff!important;
    box-shadow: 0 0 10px -2px #3e30ff86;
}
.design_div {
    border: 4px solid #fff;
}
.flexDiv {
    margin: 0 3px;
}
.flexDiv img.img_des {
    max-width: 100%;
    max-height: 520px;
    min-height: 520px;
}
.designed_area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.designed_area *::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.designed_area *::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.designed_area *::-webkit-scrollbar-thumb {
  background: #eee; 
}

/* Handle on hover */
.designed_area *::-webkit-scrollbar-thumb:hover {
  background: #ddd; 
}

.icon_contains {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    width: 68%;
    height: auto;
}
.icon_contains .icon_div {
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 4px;
    background: #6272ee;
    margin: 0px 5px 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.icon_contains .icon_item {
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 30px;
    line-height: 35px;
}
.panel_item {
    width: 100%;
    height: 210px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.panel_item .text_block>h5 {
    position: absolute;
}
.left_aside_icon {
    overflow: auto !important;
    width: 100px;
    max-width: 25%;
    padding: 10px 7px;
    background: rgb(0 0 0 / 16%);
    box-shadow: 2px 0 4px rgb(0 0 0 / 10%);
}

.right_aside_panel {
    width: 75%;
    overflow-y: auto!important;
}