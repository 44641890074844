.text-black {
    color: #000;
}
.cursor {
    cursor: pointer;
}
.kycPreview {
    background: #ffffff;
    border-radius: 20px;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 3em;
}
.scroll_here {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.kycPreview button:disabled , .kycPreview button:disabled i {
    cursor: no-drop;
    color: rgb(187, 99, 99);
    opacity: 0.7;
}
.kycPreview  h5, .kycPreview h6 {
    font-family: serif;
}
.kycPreview .navKyc {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.kycPreview .navKyc  * {
    font-size: 16px;
    color: #41c4d9;
    font-weight: 700;
}
.kycPreview .boxLayut img.img-fluid {
    width: 114px;
    height: 114px;
    margin: auto;
    margin-bottom: 10px;
    background: #ddd;
    border-radius: 50%;
}
.kycPreview .genderLayut img.img-fluid {
    width: 65px;
    height: 65px;
    margin: auto;
    padding: 10px;
    margin-bottom: 10px;
    background: #ddd;
    border-radius: 50%;
}
.kycPreview .genderLayut h6 {
    color: #41c4d9;
    font-family: serif;
    font-weight: 700;
}
.kycPreview .genderLayut div.active img.img-fluid {
    background: #41c4d9;
}