#mobile_overview *::-webkit-scrollbar {
  width: 3px;
}

/* Track */
#mobile_overview *::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#mobile_overview *::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
#mobile_overview *::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

#chr_phone {
  width: 48.88vh;
  height: 85vh;
  /* position: absolute; */
  top: 0;
  right: 10px;
  margin: auto;
}
.chr_phone_content {
  width: 100%;
  height: 100%;
}
#chr_phone > div > button {
  font-size: 12.5px;
  padding: 3px 6px;
}
#mobile_overview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 7px;
  border-radius: 30px;
  background-color: #00c8bf;
  border: 1px solid #c5c5c5;
  box-shadow: 1px 1px 1px #fff2f2;
}
#phone-bar {
  left: 24px;
  z-index: 999;
  position: relative;
  top: calc(-1 * 85vh + 7px);
  width: calc(48.88vh - 48px);
  height: calc(87vh - 24px);
  border-radius: 20px;
  color: white;
  display: block;
  pointer-events: none;
  user-select: none;
  font-size: 1.5vh;
}

/* quiz preview css */
.previewArea {
  height: 100%;
  padding: 0em;
  overflow-y: auto;
  background: #f6f6f6;
  border-radius: 20px;
}
.recentQuiz > h5 {
  font-family: serif;
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.23em;
}
.rct_quiz_item {
  margin-bottom: 0.8em!important;
}
.rct_quiz_item p {
  max-height: 18px;
  font-size: 12.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 158px;
  height: 1.2em;
  white-space: nowrap;
}
.rct_quiz_item h6 {
  font-family: serif;
  font-style: italic;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 157px;
  height: 1.2em;
  white-space: nowrap;
}
.userArea h6 {
  font-family: serif;
  font-style: normal;
  font-size: 16px;
}
.userArea img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.topQzsBg {
  padding: 1.75em 1em 5em;
  position: relative;
  background: #8dd8d3;
  border-radius: 10px;
  margin-bottom: 4em;
  height: 215px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.courseList {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -75px;
  padding: 0 5px;
  overflow: hidden;
}
.courseList .slick-list {
  padding: 1.25em;
}
.slick-slide.slick-active.slick-current {
  transform: scale(1.13);
}
.currentQuiz {
  padding: 0;
  width: 112px;
  height: 138px;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 3px 5px #00000045;
}
.currentQuiz:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #0000006b;
}
.currentQuiz .playBtn {
  color: #fff;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 40px;
  font-size: 1.3em;
  border-radius: 50%;
  border: 2px solid #fff;
}

/* play quiz css */
.PlayQuiz {
  padding: 10px;
  position: relative;
}
.PlayQuiz .cardTop {
  background-color: #fff;
  width: 100%;
  min-height: 140px;
  height: min-content;
  border-radius: 15px;
  padding: 20px 15px 10px;
  box-shadow: 0px 3px 6px -2px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.PlayQuiz .score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}
.PlayQuiz .question-count {
  text-align: center;
  margin-top: 2.3em;
  margin-bottom: 0.75em;
}
/* QUESTION/TIMER/LEFT SECTION */
.PlayQuiz .question-section {
  width: 100%;
  position: relative;
  margin: 35% 0 8%;
}

.PlayQuiz .question-count span {
  font-size: 18px;
  color: #18968e;
}

.PlayQuiz .question-text {
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}

.PlayQuiz .timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 1px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.PlayQuiz .answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PlayQuiz button {
  width: 100%;
  position: relative;
  font-size: 13.5px;
  color: #212529;
  font-weight: 600;
  font-style: italic;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  padding: 8px 15px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  box-shadow: 0px 3px 4px -2px rgb(0 0 0 / 10%);
}
.PlayQuiz button > input[type="radio"] {
  position: absolute;
  right: 10px;
}
.PlayQuiz img.cardImgTp {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -17px;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%);
  box-shadow: 0 2px 6px #00000057;
}
.PlayQuiz .correct {
  background-color: #2f922f;
}

.PlayQuiz .incorrect {
  background-color: #ff3333;
}

.PlayQuiz button:hover {
  background-color: #dedede;
}

.PlayQuiz button:focus {
  outline: none;
}

.PlayQuiz button svg {
  margin-right: 5px;
}
