.menu_steps {
  margin-bottom: 15px;
  margin-top: 0;
  text-align: center;
}
.menu_steps .step_menu {
  background: #00c8c0;
  color: #fff;
  min-width: 7%;
  margin: 0 4px;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease 0s;
}
.step_menu .fa {
  position: absolute;
  top: 4px;
  z-index: 1;
  color: #464646;
  font-size: 1.4em;
  font-weight: bold;
}
.step_menu h6 {
  border-bottom: 2px solid #464646;
  display: inline-block;
  padding: 6px 10px;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  color: #464646;
  font-size: 13px;
  transition: all 0.3s ease 0s;
}
.menu_steps .step_menu.active,
.menu_steps .step_menu:hover {
  background: #ff9800;
  transition: all 0.3s ease 0s;
}
.menu_steps .step_menu.active h6,
.menu_steps .step_menu:hover h6 {
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
